import React, { useContext, useEffect } from "react"
import * as shortid from "shortid"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import LangContext from "../../contexts/LangContext"
import { useMainMenu } from "../../contexts/MainMenuProvider"

const SEO = ({ schema, ogp, meta, breadcrumbs, noindex }) => {
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]
  const { isOpenMenu } = useMainMenu()

  const ogpMeta = [
    <meta
      key={shortid.generate()}
      property="og:locale"
      content={i18n.ogLang}
    />,
    <meta
      key={shortid.generate()}
      property="og:site_name"
      content="Medicina Interventionala"
    />,
  ]

  ogp.forEach(tag => {
    ogpMeta.push(
      <meta
        key={shortid.generate()}
        property={tag.property}
        content={tag.content}
      />
    )
  })

  let parsedSchema =
    schema && schema.length && typeof schema !== "object"
      ? JSON.parse(schema)
      : { "@graph": [] }

  if (!parsedSchema["@graph"]) {
    parsedSchema["@graph"] = []
  }

  parsedSchema["@graph"].push({
    "@context": "https://schema.org",
    "@type": "Hospital",
    "@id": "https://medicina-interventionala.ro/#website",
    "url": "https://medicina-interventionala.ro/",
    "name": "Monza Ares",
    "image": "https://medicina-interventionala.ro/static/logo_white-794cbbbdc73cc0277c977a3ee3300014.png",
    "alternateName": ["Centrele MONZA ARES"],
    "Medicalspecialty": "http://schema.org/Cardiovascular",
    "logo": "https://medicina-interventionala.ro/static/logo_white-794cbbbdc73cc0277c977a3ee3300014.png",
    "slogan": "Inovatie In Cardiologie",
    "pricerange": "$$$",
    "Paymentaccepted": ["Cash", "card"],
    "currenciesAccepted": "RON",
    "brand": {
      "name": ["ARES", "MONZA ARES", "Centrele MONZA ARES", "Spitalul MONZA ARES"]
    },
    "legalName": "SC CENTRELE DE EXCELENTA ARES SRL",
    "isAcceptingNewPatients": "true",
    "award": "Centrul European de Training si Excelenta in Terapia Fibromului Uterin",
    "founder": "Dr. Oren Iancovici",
    "foundingDate": "19.04.2013",
    "taxID": "31541081",
    "email": "office@centrele-ares.ro",

    "sameAs": [
      "https://www.facebook.com/CentreleAres/",
      "http://www.youtube.com/user/MNLCardiologie",
      "https://www.linkedin.com/company/centrul-de-excelenta-in-cardiologie-si-radiologie-interventionala-med-new-life",
      "https://twitter.com/centreleares"
    ],
    "address": {
      "@type": "postalAddress",
      "addressLocality": "Bucuresti, Romania",
      "PostalCode": "021967",
      "streetAddress": "Strada. Tony Bulandra nr. 27",
      "telephone": "+40319300"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+40319300",
      "contactType": "customer service",
      "availableLanguage": ["en", "Romanian"]
    }
  })

  if (breadcrumbs.length) {
    parsedSchema["@graph"].push({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      numberOfItems: breadcrumbs.length,
      itemListElement: breadcrumbs.map((breadcrumb, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: `${process.env.GATSBY_BASE_URL}${breadcrumb.path}`,
        name: breadcrumb.name,
      })),
    })
  }

  const cleanedParsedSchema = {
    "@context": "https://schema.org",
    "@graph": []
  }

  /* Uncomment this in case we want to avoid having duplicates: Keep in mind that duplicates should be valid 
  const usedTypes = []
   
  parsedSchema["@graph"] = parsedSchema["@graph"].reverse().filter(value => {
    const type = value["@type"]
  
    if (usedTypes.find(el => el === type)) {
      return false
    }
  
    usedTypes.push(type)
    return true
  }).reverse() */

  return (
    <Helmet
      title={meta.title}
      htmlAttributes={{
        class: isOpenMenu ? "unscrollable-content" : "",
      }}
      bodyAttributes={{
        class: isOpenMenu ? "unscrollable-content" : "",
      }}
    >
      <html lang={i18n.siteLanguage} />
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />

      <meta
        name="google-site-verification"
        content="xsozJPwdNZYy2ha6BrvH5xk6VAwgMSe5BLVugVU9qqE"
      />

      <meta
        name="facebook-domain-verification"
        content="99l09j24c2p53oy0sobli9mr0p4kdl"
      />

      {noindex === true && <meta name="robots" content="noindex" />}

      <script type="application/ld+json">{JSON.stringify(parsedSchema)}</script>
      {ogpMeta}
    </Helmet>
  )
}

export default SEO

SEO.defaultProps = {
  schema: {},
  breadcrumbs: [],
  noindex: false
}

SEO.propTypes = {
  schema: PropTypes.string,
  ogp: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array,
  noindex: PropTypes.bool
}
